import { useState, useEffect } from 'react';
import axios from 'axios';
import * as Icon from 'react-bootstrap-icons';
import { useCookies } from 'react-cookie';

import '../../assets/css/upload-file.css';

export default function UploadFile() {
    // Declare some states for the function
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [mergedFiles, setMergedFiles] = useState([]);
    const [isConverted, setIsConverted] = useState(false);
    const [isMerged, setIsMerged] = useState(false);
    const [isZipped, setIsZipped] = useState(false);
    const [isFileChosen, setIsFileChosen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConverting, setIsConverting] = useState(false);
    const [isMerging, setIsMerging] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [linkDownloadZipFile, setLinkDownloadZipFile] = useState("");

    const formData = new FormData();

    // Declare cookie named operation_id and it's method (setCookie, removeCookie)
    const [cookie, setCookie, removeCookie] = useCookies(["operation_id"]);

    // Declare the unique key for each file chosen from input file
    let uniqueKey = 1;

    const SUBMIT_CONVERT_FILE_ACTION = "ConvertFile";
    const SUBMIT_MERGE_FILE_ACTION = "MergeFile";
    const RECAPCHA_URL = "https://www.google.com/recaptcha/api.js"
    const CAPTCHA_SITE_KEY = "6Lc0LT4nAAAAAJ4oqcrq7aQnuQewqefexcb7K-cM"

    // Use this to handle any effect on this page. (The first rendering and when refresh this page)
    useEffect(() => {
        // Load the Javascript API 
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `${RECAPCHA_URL}?render=${CAPTCHA_SITE_KEY}`, function () {
            console.log("Script loaded!");
        });

        if (cookie.operation_id) {
            // Reset the state of isZipped to false
            setIsZipped(false);

            // Call deleteCurrentSession function
            deleteCurrentSession();

            // Remove the operation_id cookie
            removeCookie("operation_id");
        }
    }, []);

    function handleChosenFiles(e, files) {
        const uploaded = [...uploadedFiles];

        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1)
                uploaded.push(file);
        })

        // Order uploaded files by alphabet
        const sortUploaded = uploaded.sort((a, b) =>
            a.name > b.name ? 1 : -1);

        // Add an unique key for each element
        sortUploaded.forEach(s => {
            s.uniqueKey = uniqueKey;
            uniqueKey++;
        });

        // Set data into uploadedFiles state
        setUploadedFiles(uploaded);

        //reset currently value of input file
        e.target.value = '';
    }

    function onFileChange(e) {
        const chosenFiles = Array.prototype.slice.call(e.target.files);

        handleChosenFiles(e, chosenFiles);
        setIsFileChosen(true);
    }

    function handleUploadedFiles() {
        if (uploadedFiles.length - 1 < 0) {
            return alert('There is no file chosen');
        }
        const fileData = [...uploadedFiles];

        fileData.forEach(file => {
            formData.append(`File ${file.uniqueKey}`, file);
        });
    }

    function handleDeleteAllFile() {
        // reset files chosen in file input and reset the uniqueKey for each element
        uniqueKey = 0;
        const resetFile = [];

        setUploadedFiles(resetFile);

        // Reset 
        setIsConverted(false);
        setIsFileChosen(false);
    }

    function handleConvertFile(e) {
        e.preventDefault();

        handleUploadedFiles();

        setIsLoading(true);
        setIsConverting(true);

        window.grecaptcha.execute(CAPTCHA_SITE_KEY, { action: SUBMIT_CONVERT_FILE_ACTION }).then(token => {
            verifyToken(token);
        });
    }

    function handleMergePdfFile(e) {
        e.preventDefault();

        handleUploadedFiles();
        setIsLoading(true);
        setIsMerging(true);

        window.grecaptcha.execute(CAPTCHA_SITE_KEY, { action: SUBMIT_MERGE_FILE_ACTION }).then(token => {
            verifyToken(token);
        });
    }

    function handleDownloadAllFile(e) {
        zipConvertedFiles(e);

        setIsDownloading(true);
        setIsLoading(true);

    }

    async function verifyToken(token) {
        try {
            await axios.post(`/api/PDFCreatorVerifyCAPTCHAToken`,
                {
                    secret: CAPTCHA_SITE_KEY,
                    token
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            ).then(function (response) {
                if (response.data.catpcha_response.success && response.data.catpcha_response.action === SUBMIT_MERGE_FILE_ACTION) {
                    mergeFiles(formData);
                    console.log("reCAPTCHA is working and merge button is running");
                } else if (response.data.catpcha_response.success && response.data.catpcha_response.action === SUBMIT_CONVERT_FILE_ACTION) {
                    convertFiles(formData);
                    console.log("reCAPTCHA is working and convert button is running");
                }
                else {
                    console.log("Sorry!! Token invalid");
                }

                console.log('Verify success');
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error);
        }
    }

    // Interact with "convert file" API - PDFCreatorConvertFile
    async function convertFiles(formData) {
        const filesToConvert = Array.prototype.slice.call(uploadedFiles);
        try {
            await axios.post(`/api/PDFCreatorConvertFile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then(function (response) {
                // Set operation_id to a cookie
                setCookie("operation_id", response.data.operation_id, { path: "/", expires: new Date(Date.now() + 86400), sameSite: 'None', secure: true });

                //Get values and key names of 'response object'
                let contentValue = Object.values(response.data.content);
                let contentKey = Object.keys(response.data.content);

                let i = 0;
                //Add new properties for uploadFiles State
                filesToConvert.forEach(c => {
                    c.downloadLink = contentValue[i];
                    c.convertedFileName = contentKey[i];
                    i++;
                });

                setUploadedFiles(filesToConvert);
                setIsConverted(true);
                setIsConverting(false);
                setIsLoading(false);

                console.log('Convert successfully');
            })
                .catch(err => console.log(err))
        } catch (error) {
            console.error(error);
        }
    }

    // Interact with "merge file" API - PDFCreatorMergeFile
    async function mergeFiles(formData) {
        const mergeFile = [...mergedFiles];
        try {
            await axios.post(`/api/PDFCreatorMergeFile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then(function (response) {
                // Set operation_id to a cookie
                setCookie("operation_id", response.data.operation_id, { path: "/", expires: new Date(Date.now() + 86400), sameSite: 'Strict', secure: true });

                const mergeFileResponse = {};
                mergeFileResponse.name = "your_merged_file.pdf";
                mergeFileResponse.mergedFileName = "your_merged_file.pdf";
                mergeFileResponse.downloadLink = response.data.merged_pdf_file;
                mergeFileResponse.uniqueKey = response.data.operation_id;

                mergeFile.push(mergeFileResponse);

                setMergedFiles(mergeFile);
                setIsMerged(true);
                setIsMerging(false);
                setIsLoading(false);

                console.log('Merge successfully');
            }).catch(err => console.error(err))
        } catch (error) {
            console.error(error);
        }
    }

    // Interact with "delete current session" API - PDFCreatorDeleteCurrentSession
    async function deleteCurrentSession(e) {
        const operation_id = cookie.operation_id;
        try {
            await axios.post(`/api/PDFCreatorDeleteCurrentSession`,
                {
                    operation_id: operation_id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                if (response.status === 200) {
                    console.log("Clean the resource successfully: ", response);
                }
            })
                .catch(err => console.log(err));
        } catch (error) {
            console.error(error);
        }
    }

    // Interact with "Zip converted files" API - PDFCreatorZipConvertedFiles
    async function zipConvertedFiles(e) {
        const operation_id = cookie.operation_id;
        console.log('operation_id', operation_id);
        try {
            await axios.post(`/api/PDFCreatorZipConvertedFiles`,
                {
                    operation_id: operation_id
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                if (response.status === 200) {
                    // Update the "linkDownloadZipFile" state with the link that was returned after calling ZipConvertedFiles API
                    setLinkDownloadZipFile(response.data.response_content)

                    // Point to link to download after zipping files 
                    window.location.href = response.data.response_content;

                    setIsZipped(true);
                    setIsDownloading(false);
                    setIsLoading(false);
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    // Create Tbody component for the table
    function Tbody() {
        // Show files were converted
        if (isConverted) {
            const convertedFiles = uploadedFiles.map(file =>
                <tr key={file.uniqueKey}>
                    <td></td>
                    <td>{file.convertedFileName}</td>
                    <td style={{ textAlign: 'center' }}>
                        <a href={file.downloadLink} className="btn btn-outline-success" disabled={isLoading}>
                            <Icon.Download />
                        </a>
                    </td>
                </tr>
            );
            return <tbody>{convertedFiles}</tbody>
            // Show files were merged
        } else if (isMerged) {
            const mergedFile = mergedFiles.map(file =>
                <tr key={file.uniqueKey}>
                    <td></td>
                    <td>{file.mergedFileName}</td>
                    <td style={{ textAlign: 'center' }}>
                        <a href={file.downloadLink} className="btn btn-outline-success" disabled={isLoading}>
                            <Icon.Download />
                        </a>
                    </td>
                </tr>
            );
            return <tbody>{mergedFile}</tbody>
            // Show files were chosen by the users
        } else {
            const uploadFiles = uploadedFiles.map(file =>
                <tr key={file.uniqueKey}>
                    <td></td>
                    <td>{file.name}</td>
                    <td style={{ textAlign: 'center' }}>
                        <button type="button" className="btn btn-outline-danger" disabled={isLoading}
                            onClick={() => {
                                setUploadedFiles(
                                    uploadedFiles.filter(u => u.uniqueKey !== file.uniqueKey)
                                );
                                // 
                                if (uploadedFiles.length - 1 < 1) {
                                    setIsFileChosen(false);
                                    setIsConverted(false);
                                }
                            }}> <Icon.Trash />
                        </button>
                    </td>
                </tr>
            );
            return <tbody>{uploadFiles}</tbody>
        }
    }

    return (
        <>
            <div id="upload" className="upload-file py-3 border-bottom">
                <div className="row justify-content-between">
                    <div className="col-sm-6">
                        <div className="input-group mb-3">
                            {(!isConverted && !isMerged) ? <>
                                <input type="file" name="uploadFile" alt='Choose file to convert' multiple className="form-control" id="file-input" disabled={isLoading}
                                    accept='.csv,.doc,.docx,.odp,.ods,.odt,.pot,.pot,.potm,.potx,.pps,.ppsx,.ppsxm,.ppt,.pptm,.pptx,.rtf,.xls,.xlsx,
                                .pdf,.html,.htm,.txt,image/*'
                                    onChange={onFileChange} />
                            </> : null}
                        </div>
                    </div>
                    <div className="col-sm-5">
                        {
                            (!isConverted && !isMerged) && isFileChosen
                                ? <button name='Convert file' className="btn btn-outline-info" type='submit' onClick={handleConvertFile} disabled={isLoading}>
                                    {isLoading && isConverting ?
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
                                            <span className='sr-only'>&nbsp;Converting...</span>
                                        </>
                                        : <span><Icon.FiletypePdf /> Convert File</span>
                                    }
                                </button>
                                : null
                        }
                        {
                            (!isConverted && !isMerged) && isFileChosen
                                ? <button name='Merge file' className="btn btn-outline-info" onClick={handleMergePdfFile} disabled={isLoading}>
                                    {isLoading && isMerging ?
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
                                            <span className='sr-only'>&nbsp;Merging...</span>
                                        </>
                                        : <span> <Icon.BoxSeam /> Merge PDF file </span>
                                    }
                                </button>
                                : null
                        }
                        {
                            isConverted || isMerged
                                ?
                                <a href={"/"} title='PDF Creator' className='btn btn-outline-warning' style={{ float: "right", marginTop: "5px" }}>
                                    <span><Icon.ArrowLeftCircle />&nbsp;</span>
                                    Return to Home page</a>
                                : null
                        }
                        {
                            isConverted && !isZipped
                                ? <button name='Download all' className="btn btn-outline-info" onClick={handleDownloadAllFile} disabled={isLoading}
                                    style={{ float: "right" }}>
                                    {isLoading && isDownloading ?
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
                                            <span className='sr-only'>&nbsp;Downloading...</span>
                                        </>
                                        : <span> <Icon.Download /> Download all </span>
                                    }
                                </button>
                                : isConverted && isZipped ?
                                    <a title='Download all' href={linkDownloadZipFile} className="btn btn-outline-info" style={{ float: "right", margin: "5px 5px 0px 0px" }}>
                                        <span> <Icon.Download /> Download all </span>
                                    </a>
                                    : null
                        }
                        {
                            isFileChosen && (!isConverted && !isMerged)
                                ? <button name='Delete all' className="btn btn-outline-danger" onClick={handleDeleteAllFile}
                                    disabled={isLoading} >
                                    <Icon.Trash /> Delete all
                                </button>
                                : null
                        }
                    </div>
                    {
                        isLoading ? <div className='progress-active'>
                            <span className="spinner-grow text-success upload-spinner" role="status" aria-hidden="true"> </span>
                            <span className='sr-only'>&nbsp; This action will take some minutes...</span>
                        </div>
                            : null
                    }
                </div>
                {
                    isFileChosen ? <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>File name</th>
                                {/* <th>File type</th> */}
                                <th style={{ textAlign: 'center' }}>Action</th>
                            </tr>
                        </thead>
                        <Tbody />
                    </table>
                        : null
                }
            </div >
        </>
    )
}