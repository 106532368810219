import logoWiinte from '../../assets/images/w.png'
// import { Outlet, Link } from 'react-router-dom';

export default function Header() {
    return (
        <>
            <header id="header">
                <nav className="navbar fixed-top navbar-expand-sm navbar-light bg-light">
                    <div className="container">
                        <a className="navbar-brand" href={`/`} title="PDF Converter">
                            <img
                                src={logoWiinte}
                                alt="WIINTE logo"
                                width={30}
                                height={30}
                                className="d-inline-block align-text-top logo-header"
                            />
                            FREE PDF Converter
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    {/* <Link className="nav-link active" aria-current="page" to={`/home`}>Home</Link> */}
                                    <a className="nav-link active" aria-current="page" href={`/`} title="PDF Converter">
                                        Home
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="#help" title="PDF Converter - User manual">
                                        Help
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}