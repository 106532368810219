import Footer from "./layout/footer";
import Header from "./layout/header";
import Main from "./layout/main";

export default function MasterPage() {
    return (
        <>
            <div id="pdf-creator" className="container-fluid">
                {/* ======= Header section======= */}
                <Header />

                {/* ======= Main content section ======= */}
                <Main />

                {/* ======= Footer section ======= */}
                <Footer />
            </div>
        </>
    );
}