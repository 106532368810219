import React from 'react';
import { Helmet } from 'react-helmet'

import './App.css';
import MasterPage from './Components/master-page';

function App() {
  return (
    <>
      <Helmet>
        <title>Free PDF Converter</title>
        <meta name="description" content="Free Online PDF Converter - the best web application to convert and merge (combine) files into PDF" />
        <meta name="keywords" content="CAO IT, WIINTE, PDF Converter, PDF Creator, Convert PDF, Merge PDF, Combine PDF, PDF, Free, Online, Free PDF Converter, Convert PDF Free Online" />
        <meta name="author" content="CAOIT, WIINTE" />
        <link rel="canonical" href="https://pdfconverter.wiinte.com" />

        {/* Open Graph tags */}
        <meta property="og:title" content="WIINTE - FREE Online PDF Converter : Convert and Merge PDF" />
        <meta property="og:description" content="Free Online PDF Converter - the best web application to convert and merge (combine) files into PDF" />
        <meta property="og:image" content="/assets/images/w.png" />
        <meta property="og:url" content="https://pdfconverter.wiinte.com" />
        <meta property="og:type" content="website" />

        {/* Twitter tags */}
        <meta name="twitter:title" content="WIINTE - FREE Online PDF Converter" />
        <meta name="twitter:description" content="Free Online PDF Converter - the best web application to convert and merge (combine) files into PDF" />
        <meta name="twitter:image" content="/assets/images/favicon.ico" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <MasterPage />
    </>
  );
}

export default App;
