import Help from "../content/help";
import UploadFile from "../content/upload-file";

export default function Main() {
    return (
        <>
            <section id="main">
                <div className="container py-sm-3">
                    {/* ======= AboutUs content ======= */}
                    <UploadFile />

                    {/* ======= AboutUs content ======= */}
                    <Help />
                </div>
            </section>
        </>
    );
}