import '../../assets/css/help.css'

export default function Help() {
    return (
        <>
            <div id="help" className="row about-us border-bottom py-3">
                <h2>GDPR</h2>
                <p><i>We don't keep any of your documents on our system. All documents will be deleted when you finish the operation by returning to the home page.
                    If not all your documents will be automatically cleaned up from our system after 24 hours.</i></p>
                <h2><strong>User manual</strong></h2>
                <dl>
                    <dt>1. Choose the files you want to work with.</dt>
                    <dd></dd>
                    <dt>2. Choose your action:</dt>
                    <dd>a. Click on the "Convert File" button to convert your files into PDF.</dd>
                    <dd>b. Click on the "Merge File" button to convert your files into PDF and
                        then merge them into one PDF file.</dd>
                    <dt>3. Download your files</dt>
                    <dd></dd>
                    <dt>4. Clean up your session by going back to the home page.</dt>
                    <dd></dd>
                </dl>
            </div>
        </>
    )
}
