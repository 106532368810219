export default function Footer() {
    const year = new Date().getFullYear();
    return (
        <>
            <footer id="footer">
                <div className="container py-sm-3">
                    <p>© {year} CAOIT Inc. All rights reserved</p>
                </div>
            </footer>
        </>
    );
}